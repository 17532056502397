@import url('https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


*,
html {
  padding: 0px;
  box-sizing: border-box;
  list-style: none;
  margin: 0px;
  font-family: 'Bruno Ace SC', cursive;
  scroll-behavior: smooth;
}
 

:root {
  --pm-color: #011627;
  --sc-color: #2ec4b6;
  --trd-color: #53b3cb;
  --box-text-color: #ffff;
}


body .text-center {
  text-align: center !important;
}

body .text-main{
   color:var(--sc-color);
}

body .box-text {
  color: var(--box-text-color) !important;
}

body a {
  text-decoration: none;
}

body .header {
  width: 100%;
  height: 98vh;
  padding: 20px;
  position: relative;
}


body .wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  ;
}


body .space {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

body .space .link {
  color: var(--sc-color);
  font-size: 18px;
  cursor: pointer;
  border-bottom: 1px solid var(--sc-color);
  padding-bottom: 6px;
}

body .navbar {
  color: #ccd6f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
}



body .navbar .navbar-item {
  display: flex;
  align-items: center;
}

body .navbar .navbar-item ul {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0px;
  margin: 0px;
}


body .header button,
body .navbar button {
  border: 1px solid var(--sc-color);
  padding: 10px 20px;
  color: var(--sc-color);
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  background: transparent;
}




body .ml-2 {
  margin-left: 20px;
}

body .navbar ul li,
body .header ul li {
  color: var(--sc-color);
  font-size: 16px;
  display: flex;
  align-items: center;
  margin: 0px 15px;
}

body .navbar ul li span,
body .header ul li span {
  margin-left: 5px;
  color: #ccd6f6;
}

body .logo {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  font-family: 'Bruno Ace SC', cursive !important;
  justify-content: center;
  cursor: pointer;
  color: var(--sc-color);
  border-radius: 100%;
  border: 2px solid var(--sc-color);
  font-size: 20px;
  position: relative;
}


body .header .hero {
  padding-top: 100px;
  max-width: 950px;
  color: #8892b0;
}

body .header .hero .intro {
  color: var(--sc-color);
  font-size: 16px;
  margin-bottom: 20px;
  letter-spacing: 1px;
}





body .header .hero .name {
  margin: 10px 0px;
  font-size: 80px;
  letter-spacing: 1px;
  color: #ccd6f6;
}

body .bg-main {
  background: var(--sc-color) !important;
}

body .bg-pm {
  background: var(--pm-color) !important;
}

body .header .hero h2 {
  font-size: 50px;
  margin: 0px;
  margin-bottom: 30px;
}

body .header .hero p {
  letter-spacing: 1px;
  max-width: 750px;
  line-height: 26px;
  font-weight: 550;
}

body .header .hero p span {
  color: var(--sc-color);
}


body .header .social-links {
  position: absolute;
  top: 0px;
  left: 20px;
  height: 100%;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

body .header .social-links ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  padding: 0px;
  position: relative;
}

body .header .social-links ul li {
  margin: 10px 0px !important;
}

body .header .social-links ul li svg {
  width: 23px;
  height: 23px;
  color: #ccd6f6;
  transition: all 1s ease-in-out;
}

body .header .social-links ul li svg:hover {
  color: var(--sc-color);
}

body .header .social-links .line {
  width: 1px;
  height: 160px;
  background-color: #ccd6f6;
  left: 10px;
  bottom: -160px;
}


body .navbar .close-btn {
  cursor: pointer;
  display: none;
}

body .navbar .close-btn svg {
  width: 30px;
  height: 30px;
}

.cursor {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: var(--sc-color);
  transition: 0.1s;
  pointer-events: none;
  mix-blend-mode: difference;
  transform: translate(-50%, -50%);
  z-index: 199;
}


div:hover .cursor,
span:hover~.cursor,
button:hover~.cursor,
li:hover~.cursor,
p:hover~.cursor,
section:hover~.cursor,
h1:hover~.cursor,
h2:hover~.cursor,
h3:hover~.cursor,
a:hover~.cursor {
  transform: scale(6);
}


/** Work experience */


body .work-experience {
  padding: 40px 20px;
  display: block;
}

body .work-experience h1 span {
  color: var(--sc-color);
}

body .work-experience h1 {
  font-size: 40px;
  margin: 0px;
  margin-bottom: 20px;
}

body .work-experience .work-row {
  padding: 20px 0px;
}

body .work-experience .work-row-item {
  color: var(--dark);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
}

body .work-experience .work-row-item.reverse {
  flex-direction: row-reverse;
}


body .work-experience .work-row-item .company-box {
  position: relative;
  width: 200px;
  min-width: 200px;
  height: 100px;
  border: 2px solid var(--sc-color);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: var(--sc-color);
  margin-right: 48px;
}


body .work-experience .work-row-item .company-box::before {
  content: '';
  position: absolute;
  top: 50px;
  right: -50px;
  width: 50px;
  height: 2px;
  background-color: var(--sc-color);
}

body .work-experience .work-row-item.reverse .company-box::before {
  left: -50px;
}

body .work-experience .work-row-item.reverse .company-box {
  margin-right: 0px;
  margin-left: 48px;
}

body .work-experience .work-row-item .company-box h3 {
  font-size: 22px;
  margin: 0px;
  margin-top: 10px;
}


body .work-experience .work-row-item .company-box p {
  font-size: 15px;
}

body .work-experience .work-row-item.reverse .text-data {
  padding-right: 20px;
  padding-left: 0px;
  border-left: none;
  border-right: 2px solid var(--sc-color);
}

body .work-experience .work-row-item .text-data {
  padding-left: 20px;
  border-left: 2px solid var(--sc-color);
  width: 100%;
}

body .work-experience .work-row-item .text-data h2 {
  font-size: 20px;
}


body .work-experience .work-row-item .text-data p {
  color: #5c6b73;
  font-size: 15px;
  letter-spacing: 1px;
}

body .work-experience .work-row-item .text-data .date {
  font-size: 13px;
  color: var(--sc-color);
  margin: 5px 0px;
  letter-spacing: 1px;
}


body .work-experience p div {
  width: 100%;
  padding: 0px;
  display: block;
}

body .work-experience p li {
  margin-bottom: 20px !important;
  list-style: disc !important;
}


/**Skills */

body .skills {
  padding: 40px 20px;
  min-height: 650px;
  margin-bottom: 40px;
}

body .skills-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0px;
}

body .skills h2 span {
  color: #ccd6f6;
}

body .skills h2 {
  font-size: 40px;
  margin: 0px;
  color: var(--sc-color);
}


.main {
  margin: 20px 0px;
  display: flex;
  --s: 150px;
  /* size  */
  --m: 4px;
  /* margin */
  --f: calc(1.732 * var(--s) + 4 * var(--m) - 1px);
}

.container {
  font-size: 0;
  /*disable white space between inline block element */
}


.container .skill-row-item-shape {
  width: var(--s);
  margin: var(--m);
  height: calc(var(--s)*1.1547);
  display: inline-block;
  font-size: initial;
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  background: var(--trd-color);
  margin-bottom: calc(var(--m) - var(--s)*0.2885);
  transition: all 1s ease-in-out;
  transform: scale(1);
}

.container .skill-row-item-shape:hover {
  transform: scale(0.9);
}


.container .skill-row-item-shape svg {
  color: var(--trd-color);
  width: 60px;
  height: 60px;
}

.container .skill-row-item-shape .ct {
  width: calc(var(--s) - 20px);
  margin: var(--m);
  height: calc(calc(var(--s)*1.1547) - 25px);
  background-color: #011627;
  margin-top: 13px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
}



.container .skill-row-item-shape:nth-child(odd) {
  background: var(--sc-color);
}

.container .skill-row-item-shape:nth-child(odd) svg {
  color: var(--sc-color);
}


.container::before {
  content: "";
  width: calc(var(--s)/2 + var(--m));
  float: left;
  height: 120%;
  shape-outside: repeating-linear-gradient(#0000 0 calc(var(--f) - 3px),
      #000 0 var(--f));
}



@media screen and (max-width:1300px) {
  body .wrapper {
    max-width: 1100px;
  }

  body .social-links li svg {
    width: 18px !important;
    height: 18px !important;
  }

  body .main {
    --s: 125px;
  }

  body .skills {
    min-height: 400px;
  }

  body .projects-container .projects-row .project-image-frames .macbook {
    width: 600px;
    max-width: 800px;
  }
}


/* Projects */


body .projects {
  min-height: 500px;
}

body .projects h2 span {
  color: #000;
}

body .projects h2 {
  font-size: 40px;
  margin: 0px;
  color: var(--sc-color);
}


body .project-card .flex {
  display: flex;
  align-items: center;
  margin: 4px 0px;
  color: #ffc107;
  font-size: 14px !important;
}


body .project-card .flex .blob {
  background: #ffc107;
  border-radius: 50%;
  margin-right: 20px;
  margin-left: 20px;
  height: 10px;
  width: 10px;
  box-shadow: 0 0 0 0 rgb(255, 211, 14);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(255, 189, 114);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgb(255, 222, 76);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(255, 211, 14);
  }
}


/* Footer */


body .footer {
  min-height: 200px;
  width: 100%;
}

body .footer .info {
  padding: 30px 20px;
}

body .footer .footer-down {
  padding: 10px 20px;
}

body .footer .social-links {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

body .footer .social-links ul {
  display: flex;
  align-items: center;
  padding: 0px !important;
}

body .footer .social-links ul {
  display: flex;
  margin-top: 30px;
}

body .footer .social-links ul li {
  width: 60px;
  height: 60px;
  margin: 5px 10px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--sc-color);
  border-radius: 100%;
  border: 2px solid var(--sc-color);
  font-size: 20px;
  position: relative;
}

body .footer .social-links ul li svg {
  color: var(--sc-color);
  width: 23px !important;
  height: 23px !important;
}

body .footer h2 span {
  color: #ccd6f6;
}

body .footer h2 {
  font-size: 40px;
  margin: 0px;
  color: var(--sc-color);
}


body .footer .contacts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

body .footer .contacts .box {
  padding: 10px 20px;
  border: 2px solid var(--sc-color);
  color: var(--sc-color);
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  background: transparent;
  margin: 10px;
  min-width: 250px;
  text-align: center;
  letter-spacing: 1px;
}


body .footer .footer-down {
  padding: 0px 20px;
}

body .footer .footer-down .contacts {
  border-left: 20px solid var(--pm-color);
  border-right: 20px solid var(--pm-color);
  padding: 10px 0px;
}

body .projects-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 20px 0px;
}

body article .dribbble {
  position: absolute;
  top: 10px;
  left: 20px;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255, 54, 81);
  padding: 10px;
  z-index: 1000;
}

body article .dribbble svg {
  width: 44px;
  height: 44px;
}




body .form-card,
.project-card {
  width: 300px;
  min-width: 455px;
  height: 600px;

  --color: var(--sc-color);
  /* the border color */
  --border: 5px;
  /* the border thickness*/
  --offset: 20px;
  /* control the offset*/
  --gap: 5px;
  /* the gap on hover */

  --_c: var(--color) var(--border), #0000 0 calc(100% - var(--border)), var(--color) 0;
  --_o: calc(3*var(--offset));
  --_p: calc(3*28px);
  padding:
    calc(var(--gap) + var(--border)) calc(var(--gap) + var(--border) + var(--offset)) calc(var(--gap) + var(--border) + var(--offset)) calc(var(--gap) + var(--border));
  background:
    linear-gradient(var(--_c)) var(--_o) var(--_o),
    linear-gradient(90deg, var(--_c)) var(--_o) var(--_o);
  background-size: calc(100% - var(--_o)) calc(100% - var(--_o));
  background-repeat: no-repeat;
  transition: .5s;
  margin: 10px 5px;
}

body .form-card {
  height: 620px;
}


.projects .project-card:nth-child(odd) {
  width: 300px;
  min-width: 455px;
  height: 600px;

  --color: var(--trd-color);
  /* the border color */
  --border: 5px;
  /* the border thickness*/
  --offset: 20px;
  /* control the offset*/
  --gap: 5px;
  /* the gap on hover */

  --_c: var(--color) var(--border), #0000 0 calc(100% - var(--border)), var(--color) 0;
  --_o: calc(3*var(--offset));
  --_p: calc(3*28px);

  padding:
    calc(var(--gap) + var(--border)) calc(var(--gap) + var(--border) + var(--offset)) calc(var(--gap) + var(--border) + var(--offset)) calc(var(--gap) + var(--border));

  background-size: calc(100% - var(--_o)) calc(100% - var(--_o));
  background-repeat: no-repeat;
  transition: .5s;
  margin: 10px 5px;
}

body .form-card:hover,
body .projects-row .project-card:hover {
  background-size: calc(100% - var(--_p)) calc(100% - var(--_p));
}


body .card-data {
  transition: .5s;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 10px;
  background: #ffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}

body .projects-row .project-card:hover .card-data {
  transform: scale(0.95);
}

.project-card .card-data .image {
  position: relative;
  height: 250px;
}

.project-card .card-data .image .badge {
  padding: 7px 15px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.project-card .card-data img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 6px
}

body .card-data h3 {
  font-size: 16px;
  margin-top: 10px;
  font-weight: 600;
  color: #011627;
}

body .card-data p {
  color: #5c6b73;
  font-size: 15px;
  letter-spacing: 1px;
}

body .card-data button {
  border-radius: 0px;
  margin-top: 10px;
  color: #ffff;
  background: var(--pm-color);
  border: 2px solid var(--pm-color);
}

body .card-data button:hover {
  border-color: var(--sc-color) !important;
  color: var(--sc-color) !important;
}

body .projects section ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-wrap: wrap;
  width: max-content;
  max-width: 900px;
  margin: 20px auto;
  padding: 8px 20px;
  background: #ffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}

body .projects section ul li {
  padding: 5px;
  margin-right: 15px;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

body .projects section ul li.previous,
body .projects section ul li.next {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: var(--sc-color);
}


body .projects section ul li.selected {
  background: var(--pm-color);
  color: #fff;
}

body .projects section ul li.next {
  margin-right: 0px !important;
}

body .projects section ul li.previous a,
body .projects section ul li.next a {
  color: #fff;
}

body .projects section .skills-box {
  display: flex;
  flex-wrap: wrap;
}

body .projects section .skills-box .skill {
  margin-right: 15px;
  font-size: 14px;
  color: var(--trd-color);
  display: flex;
  align-items: center;
  margin-top: 10px;
}

body .projects section .skills-box .skill .dot {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  margin-right: 5px;
  background: var(--pm-color);
}

@media screen and (max-width:1300px) {

  body .project-card,
  body .form-card {
    min-width: 355px !important;
    height: 630px !important;
  }

  body .project-card .image,
  body .project-card img {
    height: 200px !important;
  }
}


/**Resume */

body .resume {
  height: 100vh;
  width: 100%;
  background: var(--pm-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

body .resume-container .nv {
  position: fixed;
  width: 100%;
  left: 0px;
  top: 0px;
  padding: 20px;
}

.resume .block {
  width: 100%;
  max-width: 430px;
  padding: 10px;
}



body .resume .btn {
  width: 100%;
  text-align: center;
  padding: 10px 20px;
  color: var(--sc-color);
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  background: var(--sc-color);
  color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

body .resume .btn svg {
  margin-right: 10px;
}





@media screen and (max-width:1000px) {

  body .header {
    min-height: 88vh;
  }

  body .hero a button {
    font-size: 14px !important;
  }

  body .header .hero {
    padding-top: 60px;
    padding-left: 45px;
  }

  body .header .hero h1 {
    font-size: 30px !important;
  }

  body .header .hero h2 {
    font-size: 28px !important;
  }

  body .header .hero p {
    font-size: 14px;
  }

  body .skills .skills-row .main .container {
    min-height: 1400px;
  }

  body .navbar .close-btn {
    display: block;
    cursor: pointer;
  }

  body .navbar-item .navbar-item {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--pm-color);
    z-index: 100;
    display: none;
  }


  body .navbar-item .navbar-item.active {
    display: flex !important;
  }

  body .navbar-item .navbar-item svg {
    width: 40px;
    height: 40px;
    fill: #ffff;
    color: #ffff;
  }

  body .navbar-item .navbar-item ul,
  body .navbar-item .navbar-item .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  body .navbar-item .navbar-item ul li,
  body .navbar-item .navbar-item .buttons button {
    margin: 10px 0px;
  }

  body .space h1,
  body .space h2 {
    font-size: 24px !important;
  }

  body .space div:last-child {
    display: none;
  }


  body .work-row-item {
    display: block !important;
  }

  body .work-row-item .company-box {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  body .work-row-item .company-box::before {
    position: relative !important;
    display: none;
  }

  body .work-row-item .text-data {
    padding-top: 20px;
    border: none !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  body .skills .container {
    padding: 0px !important;
    margin: 0px !important;
    padding-left: 20px;
    max-width: 340px;
  }


  body .projects .space {
    padding-left: 20px !important;
  }

  body .projects-row {
    flex-direction: column !important;
  }

  body .projects-row .project-card,
  body .form-card {
    min-width: 90% !important;
    width: 90% !important;
    margin: 10px 0px !important;
  }


  body .footer .box {
    font-size: 13px !important;
  }

  body .hrt {
    padding-left: 20px;
    font-size: 18px;
  }

  body .projects-row .project-card {
    min-height: 550px !important;
    height: auto !important;
  }

  body .projects-row .project-card .f1 {
    flex-direction: column;
    align-items: flex-start;
  }

  body .projects-row .project-card .blob {
    margin-left: 0px;
    margin-top: 7px;
  }

  body .form-card p {
    display: none;
  }
}



body .projects .container {
  padding: 0px !important;
}

body .pag {
  padding: 0px 20px;
}



@media screen and (max-width:1000px) {

  body .pag {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 400px !important;
  }

  body .pag li {
    margin-top: 4px;
    margin-bottom: 4px;
  }


  body .skills .skills-row .main .container {
    min-height: 2350px;
    max-width: 270px !important;
  }

  body .space h1,
  body .space h2 {
    font-size: 20px !important;
  }

  body .hero h1.name {
    font-size: 24px !important;
  }

  body .header .hero h2 {
    font-size: 20px !important;
  }
}



/* courses */


body .courses-page {}

body .courses-page {}

body .courses-page {}

body .courses-page {}

body .courses-page {}

body .courses-page {}

body .courses-page {}

body .courses-page {}

body .courses-page {}

body .courses-page {}

body .courses-page {}

body .courses-page,
body .courses-page ul,
body .courses-page div,
body .courses-page span,
body .courses-page h1,
body .courses-page li,
body .courses-page h2,
body .courses-page p,
body .courses-page strong,
body .courses-page h3,
body .courses-page h4,
body .courses-page small,
body .courses-page button,
body .courses-page h5,
body .courses-page a {
  font-family: "Montserrat", sans-serif !important;
}


body .logo,
body .logo-area span {
  font-family: 'Bruno Ace SC', cursive !important;
}

body .courses-page .flex-item {
  display: flex;
  align-items: center;
}

body .courses-page .c-navbar .nav-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
}


body .courses-page .c-navbar .nav-items .btn { 
   width: 100%;
   text-align: center;
   padding: 10px 20px;
   color: var(--sc-color);
   cursor: pointer;
   outline: none;
   border-radius: 4px;
   background: var(--sc-color);
   color: #ffff;
   display: flex;
   align-items: center;
   justify-content: center;
}


body .courses-page .logo-area span {
  color: var(--sc-color);
  font-size: 20px;
  margin-left: 20px;
}

body .courses-page ul {
  padding: 0px !important;
  margin: 0px;
}

body .courses-page ul li {
  margin: 0px 10px;
}



body .courses-page .title{
  font-size:25px;
  font-weight:bold;
  margin-left:20px;
  margin-top:50px;
  margin-bottom:20px;
}
 

body .course-box img{  
  border-radius:0px;
  width:100%;
  height:280px;
  object-fit: cover;
}



body .courses-page ul li a {
  color: #ffff;
}

body .course-box h5{
  font-size:16px;
  margin:10px 0px;
  font-weight:700;
}

body .course-box .btn{
  border-radius:0px;
}

body .course-box .status{
  color: rgb(255, 170, 59);
  font-size:14px; 
  margin:5px 0px;
  font-weight:500;
}

body .course-box .status.st2{
  color: #ffc107 !important;
  font-size:15px;
  font-weight:550;
  margin:5px 0px;
}

body .course-box{
   display: flex;
   flex-direction: column;
   width:420px;
   margin:15px;
}


body .course-box .btn{
  margin-top:10px;
}


body .courses{
   display: flex;
   flex-wrap: wrap;    
}


/****Course dets */

body .courses-page.dets {
   background:#F8F9FA;
   min-height:100vh;
   padding-bottom:50px;
}

body .courses-page.dets .course-dets{
  display: flex;
  justify-content: space-between;
  margin-top:20px;
}

body .courses-page.dets .course-dets .playlist{
   min-width:400px;
   width:400px;
   min-height:200px;
   height: max-content;
   max-height:80vh;
   overflow: hidden;
   overflow-y: auto;
   background-color: #ffff;
   border-radius:10px; 
}
 
body .courses-page.dets .course-dets .playlist ol{
  padding:0px;
  margin:0pc;
  height: auto;
  min-height: auto; 
}

body .courses-page.dets  .playlist ol li{
 padding:10px;
 cursor: pointer;
 border-bottom:1px solid rgb(247, 247, 247);
}

body .courses-page.dets  .playlist ol .header-title{
   padding:10px 20px;
   cursor: pointer;
   background:#E9ECEF;  
}

body .courses-page.dets .course-dets .player{
   width:100%;
   min-height:500px;
   background: #000000;
}

body .courses-page.dets .course-dets .play-header{
   padding:10px 20px;
   background: var(--sc-color); 
}


body .courses-page.dets .course-dets .play-header h5{
   margin:0pc;
   font-weight:600;
   font-size:20px;
}


body .courses-page.dets .course-dets .player iframe{
   width:100% !important;
   height:100% !important;
   min-height:500px; 
}

body .courses-page.dets .content-area{
   width:100%;
   padding-right:40px;
}


body .courses-page.dets .tabs,
body .courses-page.dets .description-block{ 
  width:100%;
  min-height:50px;  
  background-color: #ffff;
  border-radius:10px;
  margin:0px;
  margin-bottom:30px;
}


body .courses-page.dets .tabs{
   display: flex; 
   align-items: center;
   padding:20px !important;
}

body .courses-page.dets .tabs li{
  font-size:18px;
  margin-right:10px;
  cursor: pointer;
  position: relative;
}

body .courses-page.dets .lesson-title{
  font-size:22px;
  font-weight:600;
  margin:10px 0px;
  margin-bottom:20px;
}

body .courses-page.dets .ct-title{
   font-size:18px;
}

body .courses-page.dets .tabs li.active::after{
    content:"";
    width:80px;
    height:4px;
    background-color: var(--sc-color);
    position: absolute;
    top:100%;
    left:0%; 
}


body .courses-page.dets .tabs li.active{
  color: var(--sc-color);
}


body .courses-page.dets .description-block{
  padding:20px;
}

 








































